@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");

.plantcards {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: flex-start;
}

.plantcard {
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 270px;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 1px 1px 2px darkgrey;
  text-align: left;
}

.round-image-plant {
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  margin-top: 10px;
}

.wall-new-plant {
  align-self: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 200px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.plantcardname {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 300;
  margin-left: 30px;
  font-family: "Roboto";
}
.plantcardimage {
  width: auto;
  height: 150px;

  align-self: center;
}

.plantcardinfo {
  margin-left: 0px;
}

.wallpic {
  height: 300px;
  width: auto;
}

.ppwall {
  height: 30px;
}

.addplantbtn {
  margin-top: 20px;
  margin-left: 100px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  background-color: #4a7f37;
  color: white;
  cursor: pointer;
  font-size: 1em;
  border: none;
  box-shadow: 1px 1px 2px darkgrey;
}

.addplantbtn:hover {
  background-color: darkgreen;
}

.noplants2 {
  /* margin-left: 50px; */
  margin-top: 32px;
  font-size: 1.2em;
  align-self: flex-start;
}
.formplantimg {
  width: 350px;
  height: auto;
  display: flex;
  margin-left: 60px;
  margin-top: 20px;
  border-radius: 20px;
}

.myplantstitle {
  text-align: center;
  font-family: "Roboto";
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 300;
  /* text-shadow: 2px 2px 4px darkgrey; */
}

.editplantcontainer {
  display: flex;
  justify-content: center;
}

.walltitle {
  text-align: center;
  font-family: "Roboto";
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 10px;
  /* text-shadow: 2px 2px 4px darkgrey; */
}

.myplantspage {
  background-color: #f0f2f5;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fa-trash {
  cursor: pointer;
}

.plantcards2 {
  display: flex;
  align-items: flex-start;
  flex-flow: wrap;
  justify-content: flex-start;
  margin-left: 5px;
  margin-top: 5px;
  overflow-x: hidden;
  height: 605px;
}

.plantcards2::-webkit-scrollbar-thumb {
  background-color: #4a7f37;
}

.plantcards2::-webkit-scrollbar {
  width: 0.4em;
}

.plantcards2::-webkit-scrollbar-track {
  box-shadow: 1px 1px 6px rgb(96 219 14 / 30%);
}

.plantcard2 {
  display: flex;
  flex-direction: column;
  height: 337px;
  width: 270px;
  justify-content: space-evenly;
  align-items: center;
  margin: 1px;
  border-radius: 10px;
  margin-bottom: 6px;
  background-color: white;
  box-shadow: 1px 1px 2px darkgrey;
  text-align: left;
}

.plantcardallinfo2 {
  /* align-self: flex-start; */
  /* margin-left: 20px; */
  font-size: 17px;
  margin-top: 15px;
}

.myplantspage2 {
  background-color: #f0f2f5;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plantcardtop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.plantcardtopbtn i {
  font-size: 0.8em;
  margin-left: 10px;
  margin-right: 10px;
}
.givemewater {
  font-size: 14px;
  margin-top: 20px;
  border-radius: 6px;
  width: 150px;
  height: 50px;
  color: white;
  align-self: center;
  background-color: #111b1e;
  box-shadow: 1px 1px 2px darkgrey;
  border: none;
  cursor: pointer;
  font-weight: 400;
}

.plantcardallinfo {
  align-self: flex-start;
  margin-left: 20px;
}
.PlantForm {
  margin-left: 80px;
  margin-bottom: 5px;
  margin-top: 20px;
  background-color: white;
  width: 500px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}

.PlantFormContainer {
  display: flex;
}

.labelplant {
  display: inline-block;
  width: 140px;
  text-align: left;
  font-size: 0.9em;
}

.selectplant {
  width: 250px;
  margin: 5px;
  height: 30px;
  font-size: 0.9em;
  border: none;
  border-radius: 15px;
  padding: 5px;
  background-color: #f0f2f5;
}

.inputplant {
  margin: 5px;
  width: 250px;
  height: 30px;
  font-size: 0.9em;
  border: none;
  background-color: #f0f2f5;
  border-radius: 15px;
  padding: 15px;
}

.interval {
  width: 60px;
}

.fileplant {
  font-size: 1em;
  width: 300px;
}

.btn-submit-plant {
  margin-top: 20px;
  margin-left: 120px;
  width: 200px;
  height: 35px;
  border-radius: 10px;
  background-color: #4a7f37;
  box-shadow: 1px 1px 2px darkgrey;
  color: white;
  font-size: 17px;
  border: none;
  font-weight: 300;
  font-family: "Roboto";
  cursor: pointer;
}

.btn-submit-plant:hover {
  background-color: darkgreen;
}

.profileplant {
  height: 200px;
  width: auto;
}

.profilemyplants {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-flow: wrap;
  justify-content: flex-start;
  margin-left: 10px;
  margin-bottom: 10px;
}

.formeditplantimg {
  width: 350px;
  height: auto;
  display: flex;
  margin-left: 60px;
  margin-top: 100px;
}

.formEditplant {
  position: relative;
  top: 20px;
  background-color: white;
  padding: 15px;
  width: 450px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px lightgrey;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userpageplantstitle {
  margin-top: 44px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-weight: 300;

  text-align: center;
}

.usersplants {
  display: flex;
  flex-direction: column;
}

.flexuserpage {
  display: flex;
  justify-content: center;
}

.flexuserpageprofile {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap-reverse;
}

.userplantspartleft {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
}

.userpageplantstitle {
  margin-top: 44px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-weight: 300;
  text-align: center;
  font-size: 29px;
}

.userplantname {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.fullbodyprofile {
  position: relative;
  top: 120px;
}

.ItemForm-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
