@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Manrope", sans-serif;
}

pre {
  font-family: "Manrope", sans-serif;
  font-size: 0.8em;
}

.mapboxgl-marker-REMOVETHIS {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: yellow;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}

body {
  background-color: #f0f2f5;
}

.inputsearchcity {
  width: 300px;
}

body::-webkit-scrollbar {
  width: 0.8em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 1px 1px 6px rgba(96, 219, 14, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #255713;
  outline: 1px solid rgb(1, 29, 5);
}

.active {
  font-weight: 900;
  background-color: rgba(22, 48, 4, 0.3);
  padding: 8px;
  border-radius: 8px;
}

.App {
  cursor: default;
}

.usercontainer:hover {
  background-color: rgba(95, 94, 94, 0.3);
}

.usernames:hover {
  color: green;
  border-bottom: darkgreen 1px solid;
}
