.AutocompletePlace {
  position: relative;
}

.AutocompletePlace-results {
  position: absolute;
  background-color: white;
  padding: 0;
  margin: 0;
  border: 1px solid #00000011;
}

.AutocompletePlace-items {
  list-style: none;
  border: 1px solid #00000011;
  cursor: pointer;
  padding: 10px 10px;
}

.AutocompletePlace-items:hover {
  background-color: #0000ff11;
}

.AutocompletePlace2 {
  position: relative;
}

.AutocompletePlace2-results {
  position: absolute;
  background-color: white;
  padding: 0;
  margin: 0;
  border: 1px solid #00000011;
}

.AutocompletePlace2-items {
  list-style: none;
  border: 1px solid #00000011;
  cursor: pointer;
  padding: 10px 10px;
}

.AutocompletePlace2-items:hover {
  background-color: #0000ff11;
}

.AutocompletePlace2 input {
  width: 350px;
  height: 30px;
  border: none;
  border-radius: 8px;
  padding: 15px;
  font-size: 1em;
}

.AutocompletePlace2 button {
  color: green;
}
