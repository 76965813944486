.ItemForm-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 25px;
  top: 80px;
  z-index: 40;
  box-shadow: 0 0 20px lightgrey;
  border-radius: 20px;
  background-color: white;
  width: 400px;
  margin: 75px auto;
  z-index: 100;
  overflow-y: auto;
  max-height: 75vh;
}

.ItemForm {
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: rgba(16, 35, 82, 0.02) 0 7px 50px 20px;
}

.ItemForm .close-link {
  text-align: right;
  font-weight: 900;
  font-size: 1.4rem;
  cursor: pointer;
  float: right;
  text-decoration: none;
  color: black;
}

.ItemForm .input {
  margin: 8px 0 12px;
  width: 100%;
  border-radius: 4px;
  display: block;
  border: none;
  font-size: 14px;
  background: #eff1f6;
  padding: 12px 12px;
}

.ItemForm select {
  display: block;
  font-size: 14px;
  line-height: 1.3;
  padding: 12px 12px;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  border-radius: 4px;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #eff1f6;
  background-image: url("/assets/arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 18px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.message {
  display: flex;
  flex-direction: row;
  background-color: #f1f2ff;
  padding: 15px;
  width: 94%;
  border-radius: 10px;
  color: #5663ff;
  font-size: 14px;
  font-weight: 500;
}

.ItemForm .message img {
  margin-right: 8px;
}
