.wallpostcontainer {
  padding-top: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 575px;
  /* position: relative; */
  /* left: 50px; */
  background-color: white;
  align-self: center;
  border-radius: 8px;
  box-shadow: 1px 1px 2px darkgrey;
}

.userlikesss {
  background: #f0f2f5;
  padding: 20px;
  border-radius: 8px;
  font-size: 15px;
  position: relative;
  bottom: 8px;
  display: flex;
  width: 205px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 1px 1px 2px darkgrey;
}

.userlikecount {
  cursor: pointer;
}

.userlikecount:hover {
  color: darkgreen;
  font-weight: 500;
}

.userlikeslist {
  display: none;
}

.wallfuldiv {
  margin-top: 25px;
}

.heart {
  cursor: pointer;

  padding: 10px;
  border-radius: 100%;
}

.heart:hover {
  background: rgb(255 21 21 / 30%);
}

.likepart {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  margin-left: 0;
  /* height: 191px; */
  font-size: 1.3em;
}

.wallpostuser {
  display: flex;
  width: 220px;
}

.wallPost {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.ppcomment {
  height: 35px;
}

.usercommenting {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.wallbody {
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
}

.fullbodywall {
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  justify-content: space-evenly;
}

.wallpic {
  background-size: cover;
  width: 88%;
  height: auto;
  cursor: pointer;
}

.wallpic2 {
  background-size: cover;
  width: 50%;
  height: auto;
}

.posttop {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.posttopuser {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
}

.ppwall {
  height: 100%;
  width: auto;
  padding-right: 10px;
}

.ppusercontainer {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.ppusercontainer2 {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  margin-bottom: 20px;
}

.ppusercontainercomment {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.posttopbtns {
  margin: 5px;
}

.wallForm3 {
  position: relative;
  left: 50px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  top: 20px;
  background-color: white;
  width: 650px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}

.posttopbtns i {
  margin: 5px;
  font-size: 1em;
}

.postdate {
  width: 100px;
}

.posttopuserinfo {
  display: flex;
  flex-direction: column;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: lightgrey;
  height: 1px;
  margin-bottom: 10px;
}

#comment {
  height: 35px;
  width: 445px;
  border-radius: 25px;
  font-size: 1em;
  padding-left: 20px;
  border: none;
  background-color: #f0f2f5;
}
#comment2 {
  height: 35px;
  width: 87%;
  border-radius: 25px;
  font-size: 1em;
  padding-left: 20px;
  border: none;
  background-color: #f0f2f5;
}

.commentForm {
  display: flex;
  margin-top: 20px;
}

.fa {
  cursor: pointer;
}

.commentsubbtn {
  margin-left: 5px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: none;
  cursor: pointer;
}

.posttitle {
  font-size: 16px;

  align-self: flex-start;
  margin-left: 20px;
  cursor: pointer;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 1em;
}

.postsubtitle {
  margin-top: 4px;
  margin-bottom: 18px;
  align-self: flex-start;
  margin-left: 20px;
  font-size: 1em;
}

.completecomment.commentpart {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 500px;
}

.commentpart {
  margin: 10px;
  margin-top: 20px;
  display: flex;
  width: 92%;
}

.commentinside {
  padding: 13px;
  display: flex;
  justify-content: space-between;
  background-color: #f0f2f5;
  border-radius: 15px;
  width: 100%;
  margin: -8px;
}
.commentuserinfo {
  font-size: 0.8em;
}

.commenttext {
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 1em;
}

.wallForm {
  /* position: relative; */
  /* left: 50px; */
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: white;
  width: 575px;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}
.wallForm2 {
  position: relative;
  left: 50px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  top: 20px;
  background-color: white;
  width: 420px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}

.labelwall {
  display: inline-block;
  font-size: 15px;
  width: 80px;
  text-align: left;
}
.inputwall {
  margin: 5px;
  width: 350px;
  height: 30px;
  font-size: 15px;
  border: none;
  background-color: #f0f2f5;
  border-radius: 15px;
  height: 35px;
  padding: 10px;
}

.btnaddwall {
  padding: 10px;
  width: 280px;
  height: 50px;
  align-self: center;
  border: none;
  background-color: #4a7f37;
  border-radius: 8px;
  color: white;
  font-size: 1em;
}

.profiletoppost {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.posttitleprofile {
  width: 600px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.userpagetitle {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 40px;
}

.searchform {
  margin-left: 0px;
  margin-top: 5px;
  width: 300px;
  background-color: white;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 1px 1px 2px grey;
}

.searchformuserpage {
  margin-left: 50px;
  margin-top: 20px;
  width: 350px;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.usercontainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  width: 265px;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}

.usercontainerpage {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 20px;
  background-color: white;
  width: 500px;
  align-items: center;
}

.allusercontainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  width: 300px;
  align-items: center;
  padding: 15px;
  height: 550px;
  overflow-x: hidden;
  box-shadow: 1px 1px 2px grey;
}

.allusercontainer b {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 14px;
}

.noplants {
  margin-left: 50px;
  margin-top: 50px;
  font-size: 1.2em;
}

.allusercontainer::-webkit-scrollbar {
  width: 0.4em;
}

.allusercontainer::-webkit-scrollbar-track {
  box-shadow: inset 1px 1px 6px rgba(96, 219, 14, 0.3);
}

.allusercontainer::-webkit-scrollbar-thumb {
  background-color: #4a7f37;
  outline: 1px solid rgb(1, 29, 5);
}

.allusercontainerpage {
  display: flex;
  flex-direction: column;
  align-self: left;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  width: 600px;
  align-items: center;
  margin-left: 50px;
}

.hruser {
  width: 100%;

  margin: 10px;
}

.wallFullPage {
  display: flex;
  flex-direction: column;
}

.userswallpageee {
}

.fulluserpagebody {
  position: sticky;
  top: 85px;
  margin-top: 25px;
}
p {
  font-size: 0.8em;
}

.oneuserbody {
  position: relative;
  top: 20px;
}

.userplantsbody {
  display: flex;
  flex-direction: column;
}

.wallpagecss {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.userplantscontainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.usersplants {
  display: flex;
}

.wallbodyprofile {
  position: relative;
  top: 50px;
}

.fullbodyprofile {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fileplant {
  font-size: 1em;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 200;
  color: white;
  background-color: #4a7f37;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #4a7f37;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

.usernameppfu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.wallpage {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: baseline;
}

.tempimgwall {
  width: 350px;
  height: auto;
  display: -webkit-flex;
  display: flex;
  margin-left: 60px;
  margin-top: 20px;
  border-radius: 20px;
}

.loadingwallgif {
  height: 35px;
}

.loadinggifwall {
  height: 100px;
  margin-left: 70px;
}

.myprofiletitle {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  width: 20%;
}

.fa-user-cog {
  font-size: 3em;
}

.oneposttitle {
  color: white;
  margin-bottom: 20px;
  position: fixed;
  left: 20px;
  top: 80px;
  font-weight: 300;
}

.onepostimage {
  height: 85%;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-self: flex-end;
}

.onepostfullscreen {
  position: fixed;
  padding-top: 68px;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(34, 34, 34, 0.9);
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.deletescreen {
  position: fixed;
  padding-top: 68px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(34, 34, 34, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.userlikes {
  position: fixed;
  padding-top: 68px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(34, 34, 34, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.yesnobtns {
  display: flex;
  justify-content: space-around;
  width: 250px;
}

.yesnobtns > button:first-child {
  height: 50px;
  width: 100px;
  background-color: darkgreen;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.yesnobtns > button:last-child {
  height: 50px;
  width: 100px;
  background-color: firebrick;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.yesnobtns > button:hover {
  box-shadow: 1px 1px 2px black;
}

.deletescreen h2 {
  color: white;
  font-size: 5vh;
  text-align: center;
  margin-bottom: 50px;
}

.onepostright {
  padding-top: 5px;
  padding-left: 15px;
  display: flex;
  width: 30%;
  height: 95%;
  background-color: white;
}

.closefull {
  font-size: 1.5em;
  font-weight: 800;
  position: fixed;
  height: 50px;
  width: 50px;
  top: 10px;
  text-align: center;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closefull:hover {
  background-color: rgba(95, 94, 94, 0.9);
  border-radius: 100%;
}

.oneposttitleimage {
  height: 95%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oneposttitleimage {
  background-color: rgba(34, 34, 34, 0.7);
  height: 95%;
}
.commentpart2 {
  margin: 10px;
  margin-top: 20px;
  display: flex;
  width: 100%;
}
/* 
.commentpartcontainer {
  margin-top: -24px;
  margin-bottom: -10px;
} */
