@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap);
.NavMain {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #4a7f37;
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  height: 55px;
  width: 100%;
  z-index: 2;
}

.NavMain .nav-list {
  margin-left: auto;
  display: flex;
}

.NavMain .nav-list li:not(:last-child) {
  margin-right: 20px;
}

.logoApp {
  font-family: Just Another Hand;
  font-size: 2.6em;
  font-weight: 200;
}

.NavMain a {
  font-weight: 500;
  margin: 5px;
}

.AutocompletePlace {
  position: relative;
}

.AutocompletePlace-results {
  position: absolute;
  background-color: white;
  padding: 0;
  margin: 0;
  border: 1px solid #00000011;
}

.AutocompletePlace-items {
  list-style: none;
  border: 1px solid #00000011;
  cursor: pointer;
  padding: 10px 10px;
}

.AutocompletePlace-items:hover {
  background-color: #0000ff11;
}

.AutocompletePlace2 {
  position: relative;
}

.AutocompletePlace2-results {
  position: absolute;
  background-color: white;
  padding: 0;
  margin: 0;
  border: 1px solid #00000011;
}

.AutocompletePlace2-items {
  list-style: none;
  border: 1px solid #00000011;
  cursor: pointer;
  padding: 10px 10px;
}

.AutocompletePlace2-items:hover {
  background-color: #0000ff11;
}

.AutocompletePlace2 input {
  width: 350px;
  height: 30px;
  border: none;
  border-radius: 8px;
  padding: 15px;
  font-size: 1em;
}

.AutocompletePlace2 button {
  color: green;
}

.Item-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  right: 25px;
  top: 120px;
  box-shadow: 0 0 50px lightgrey;
  border-radius: 20px;
  background-color: white;
  width: 450px;
  padding: 25px;
  margin: 75px auto;
  z-index: 100;
  overflow-y: auto;
  max-height: 75vh;
}

.Item-container .round-image {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  margin: 25px auto 10px;
}

.Item-container .round-image .user-img {
  width: 100%;
  object-position: 0 0;
  height: 100%;
  object-fit: cover;
}

.Item-container .round-image-user {
  min-height: 30px;
  min-width: 30px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}

.Item-container .round-image-user img {
  position: absolute;
  width: 100%;
  object-fit: cover;
}

.Item-container .info {
  margin: 10px auto;
}

.Item-container .info span {
  display: inline;
  margin: 5px 5px;
}

.Item-container .user-info {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Item-container .contact-information {
  text-align: center;
  margin-top: 20px;
  background: #f1f2ff;
  padding: 15px;
  border-radius: 10px;
  color: #5663ff;
  font-size: 14px;
  font-weight: 500;
}

.Item-container .title {
  margin: 0;
  text-align: center;
  text-transform: capitalize;
}

.Item-container .info .description,
.location {
  margin: 1em 0;
}

.Item-container .description {
  background-color: rgb(226, 226, 226);
  padding: 10px;
  border-radius: 10px;
}

.UploadWidget {
  border: 1px solid #5663ff;
  color: #5663ff;
  border-radius: 4px;
  display: inline-block;
  padding: 10px 22px;
  cursor: pointer;
  margin-bottom: 20px;
}

.UploadWidget.input[type="file"] {
  display: none !important;
}

.ItemForm-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: 25px;
  top: 80px;
  z-index: 40;
  box-shadow: 0 0 20px lightgrey;
  border-radius: 20px;
  background-color: white;
  width: 400px;
  margin: 75px auto;
  z-index: 100;
  overflow-y: auto;
  max-height: 75vh;
}

.ItemForm {
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: rgba(16, 35, 82, 0.02) 0 7px 50px 20px;
}

.ItemForm .close-link {
  text-align: right;
  font-weight: 900;
  font-size: 1.4rem;
  cursor: pointer;
  float: right;
  text-decoration: none;
  color: black;
}

.ItemForm .input {
  margin: 8px 0 12px;
  width: 100%;
  border-radius: 4px;
  display: block;
  border: none;
  font-size: 14px;
  background: #eff1f6;
  padding: 12px 12px;
}

.ItemForm select {
  display: block;
  font-size: 14px;
  line-height: 1.3;
  padding: 12px 12px;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  border-radius: 4px;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  background: #eff1f6;
  background-image: url("/assets/arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 18px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.message {
  display: flex;
  flex-direction: row;
  background-color: #f1f2ff;
  padding: 15px;
  width: 94%;
  border-radius: 10px;
  color: #5663ff;
  font-size: 14px;
  font-weight: 500;
}

.ItemForm .message img {
  margin-right: 8px;
}

.FeedBack {
  width: 100%;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
}

.FeedBack.failure {
  background-color: rgb(255, 182, 182);
  color: rgb(255, 58, 58);
}

.FeedBack.success {
  background-color: rgb(61, 194, 0);
  color: white;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Manrope", sans-serif;
}

pre {
  font-family: "Manrope", sans-serif;
  font-size: 0.8em;
}

.mapboxgl-marker-REMOVETHIS {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: yellow;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}

body {
  background-color: #f0f2f5;
}

.inputsearchcity {
  width: 300px;
}

body::-webkit-scrollbar {
  width: 0.8em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 1px 1px 6px rgba(96, 219, 14, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #255713;
  outline: 1px solid rgb(1, 29, 5);
}

.active {
  font-weight: 900;
  background-color: rgba(22, 48, 4, 0.3);
  padding: 8px;
  border-radius: 8px;
}

.App {
  cursor: default;
}

.usercontainer:hover {
  background-color: rgba(95, 94, 94, 0.3);
}

.usernames:hover {
  color: green;
  border-bottom: darkgreen 1px solid;
}

.homepage {
  background-image: url("https://media1.popsugar-assets.com/files/thumbor/sjkE0uzFsuxdfWzPvivx0j-UA18/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/04/07/792/n/1922507/882090f7336c9e28_EUsNPN3XsAEanhV/i/Plant-Lover-Living-Room-Zoom-Background.jpeg");
  background-position: 0 -200px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 750px;
  padding-bottom: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0;
}

.fulluserpagebody2 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hometitle {
  color: white;
  font-size: 11em;
  margin-bottom: 0;
  font-family: Just Another Hand;
  font-weight: 300;
  text-shadow: 8px 8px 16px black;
}

.homesubtitle {
  color: white;
  font-size: 3em;
  font-family: Roboto;
  font-weight: 300;
  text-shadow: 16px 16px 32px black;
  margin-top: 150px;
  padding-top: 20px;
}

.signin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.signinform {
  display: flex;
  flex-direction: column;
}

.signin_left {
  display: flex;
  flex-direction: column;
  background-image: url("https://cdn.apartmenttherapy.info/image/upload/v1586153210/at/news-culture/2020-04/200402-bloomscape-zoom-2.png");
  height: 100vh;
  width: 70%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  color: white;
}

.signin_left_title {
  text-align: center;
  color: white;
  font-size: 11em;
  margin-bottom: 0;
  font-family: Just Another Hand;
  font-weight: 300;
  text-shadow: 8px 8px 16px black;
}

.signin_left_subtitle {
  text-align: center;
  color: white;
  font-size: 3em;
  font-family: Roboto;
  font-weight: 200;
  text-shadow: 16px 16px 32px black;
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 50px;
}

.signin_right {
  padding: 15px;
  width: 25%;
  border-radius: 15px;
  background-color: white;
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
}

.signin_right_text {
  margin-top: 20px;
  color: black;
  font-size: 1.2em;
}

.signinform_input {
  font-size: 1.5em;
  opacity: 0.95;
  border: none;
  margin: 10px;
  border-bottom: 2px black solid;
  background: transparent;
  font-weight: 100;
  color: black;
}

.signinform_button {
  margin-top: 20px;
  width: 120px;
  height: 35px;
  font-size: 1em;
  background-color: #4a7f37;
  color: white;
  border-radius: 12px;
  border: none;
  box-shadow: 1px 1px 2px grey;
  align-self: center;
  cursor: pointer;
  padding: 25px;
  justify-self: center;
  justify-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signincontainer {
  cursor: default;
}

.here:hover {
  color: darkgreen;
  font-weight: 800;
}

.signinform_button:hover {
  background-color: darkgreen;
  box-shadow: 2px 2px 4px grey;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

.signinform_label {
  color: black;
  font-size: 1.2em;
  margin-left: 10px;
}

/* background-image: url("https://cdn.apartmenttherapy.info/image/upload/v1586153210/at/news-culture/2020-04/200402-bloomscape-zoom-2.png"); */

.containertext {
  font-size: 1.5em;
  font-weight: 100;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.homecontainer h3 {
  font-size: 2.2em;
  text-align: center;
  line-height: 1em;
}

.homeinfos {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
}

.homecontainer {
  width: 220px;
}

.formcontainersignup {
  display: flex;
}

.signupform {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-top: 100px;
}

.signupinput {
  width: 300px;
}

.signupform button {
  width: 90px;
}

.plantcards {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  justify-content: flex-start;
}

.plantcard {
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 270px;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 1px 1px 2px darkgrey;
  text-align: left;
}

.round-image-plant {
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  margin-top: 10px;
}

.wall-new-plant {
  align-self: center;
  display: flex;
  justify-content: center;
  height: 200px;
  width: 200px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.plantcardname {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 300;
  margin-left: 30px;
  font-family: "Roboto";
}
.plantcardimage {
  width: auto;
  height: 150px;

  align-self: center;
}

.plantcardinfo {
  margin-left: 0px;
}

.wallpic {
  height: 300px;
  width: auto;
}

.ppwall {
  height: 30px;
}

.addplantbtn {
  margin-top: 20px;
  margin-left: 100px;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  background-color: #4a7f37;
  color: white;
  cursor: pointer;
  font-size: 1em;
  border: none;
  box-shadow: 1px 1px 2px darkgrey;
}

.addplantbtn:hover {
  background-color: darkgreen;
}

.noplants2 {
  /* margin-left: 50px; */
  margin-top: 32px;
  font-size: 1.2em;
  align-self: flex-start;
}
.formplantimg {
  width: 350px;
  height: auto;
  display: flex;
  margin-left: 60px;
  margin-top: 20px;
  border-radius: 20px;
}

.myplantstitle {
  text-align: center;
  font-family: "Roboto";
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 300;
  /* text-shadow: 2px 2px 4px darkgrey; */
}

.editplantcontainer {
  display: flex;
  justify-content: center;
}

.walltitle {
  text-align: center;
  font-family: "Roboto";
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 10px;
  /* text-shadow: 2px 2px 4px darkgrey; */
}

.myplantspage {
  background-color: #f0f2f5;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fa-trash {
  cursor: pointer;
}

.plantcards2 {
  display: flex;
  align-items: flex-start;
  flex-flow: wrap;
  justify-content: flex-start;
  margin-left: 5px;
  margin-top: 5px;
  overflow-x: hidden;
  height: 605px;
}

.plantcards2::-webkit-scrollbar-thumb {
  background-color: #4a7f37;
}

.plantcards2::-webkit-scrollbar {
  width: 0.4em;
}

.plantcards2::-webkit-scrollbar-track {
  box-shadow: 1px 1px 6px rgb(96 219 14 / 30%);
}

.plantcard2 {
  display: flex;
  flex-direction: column;
  height: 337px;
  width: 270px;
  justify-content: space-evenly;
  align-items: center;
  margin: 1px;
  border-radius: 10px;
  margin-bottom: 6px;
  background-color: white;
  box-shadow: 1px 1px 2px darkgrey;
  text-align: left;
}

.plantcardallinfo2 {
  /* align-self: flex-start; */
  /* margin-left: 20px; */
  font-size: 17px;
  margin-top: 15px;
}

.myplantspage2 {
  background-color: #f0f2f5;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plantcardtop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.plantcardtopbtn i {
  font-size: 0.8em;
  margin-left: 10px;
  margin-right: 10px;
}
.givemewater {
  font-size: 14px;
  margin-top: 20px;
  border-radius: 6px;
  width: 150px;
  height: 50px;
  color: white;
  align-self: center;
  background-color: #111b1e;
  box-shadow: 1px 1px 2px darkgrey;
  border: none;
  cursor: pointer;
  font-weight: 400;
}

.plantcardallinfo {
  align-self: flex-start;
  margin-left: 20px;
}
.PlantForm {
  margin-left: 80px;
  margin-bottom: 5px;
  margin-top: 20px;
  background-color: white;
  width: 500px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}

.PlantFormContainer {
  display: flex;
}

.labelplant {
  display: inline-block;
  width: 140px;
  text-align: left;
  font-size: 0.9em;
}

.selectplant {
  width: 250px;
  margin: 5px;
  height: 30px;
  font-size: 0.9em;
  border: none;
  border-radius: 15px;
  padding: 5px;
  background-color: #f0f2f5;
}

.inputplant {
  margin: 5px;
  width: 250px;
  height: 30px;
  font-size: 0.9em;
  border: none;
  background-color: #f0f2f5;
  border-radius: 15px;
  padding: 15px;
}

.interval {
  width: 60px;
}

.fileplant {
  font-size: 1em;
  width: 300px;
}

.btn-submit-plant {
  margin-top: 20px;
  margin-left: 120px;
  width: 200px;
  height: 35px;
  border-radius: 10px;
  background-color: #4a7f37;
  box-shadow: 1px 1px 2px darkgrey;
  color: white;
  font-size: 17px;
  border: none;
  font-weight: 300;
  font-family: "Roboto";
  cursor: pointer;
}

.btn-submit-plant:hover {
  background-color: darkgreen;
}

.profileplant {
  height: 200px;
  width: auto;
}

.profilemyplants {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-flow: wrap;
  justify-content: flex-start;
  margin-left: 10px;
  margin-bottom: 10px;
}

.formeditplantimg {
  width: 350px;
  height: auto;
  display: flex;
  margin-left: 60px;
  margin-top: 100px;
}

.formEditplant {
  position: relative;
  top: 20px;
  background-color: white;
  padding: 15px;
  width: 450px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px lightgrey;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userpageplantstitle {
  margin-top: 44px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-weight: 300;

  text-align: center;
}

.usersplants {
  display: flex;
  flex-direction: column;
}

.flexuserpage {
  display: flex;
  justify-content: center;
}

.flexuserpageprofile {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap-reverse;
}

.userplantspartleft {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
}

.userpageplantstitle {
  margin-top: 44px;
  margin-bottom: 20px;
  font-family: Roboto;
  font-weight: 300;
  text-align: center;
  font-size: 29px;
}

.userplantname {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.fullbodyprofile {
  position: relative;
  top: 120px;
}

.ItemForm-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.wallpostcontainer {
  padding-top: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 575px;
  /* position: relative; */
  /* left: 50px; */
  background-color: white;
  align-self: center;
  border-radius: 8px;
  box-shadow: 1px 1px 2px darkgrey;
}

.userlikesss {
  background: #f0f2f5;
  padding: 20px;
  border-radius: 8px;
  font-size: 15px;
  position: relative;
  bottom: 8px;
  display: flex;
  width: 205px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 1px 1px 2px darkgrey;
}

.userlikecount {
  cursor: pointer;
}

.userlikecount:hover {
  color: darkgreen;
  font-weight: 500;
}

.userlikeslist {
  display: none;
}

.wallfuldiv {
  margin-top: 25px;
}

.heart {
  cursor: pointer;

  padding: 10px;
  border-radius: 100%;
}

.heart:hover {
  background: rgb(255 21 21 / 30%);
}

.likepart {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  align-items: center;
  width: 98%;
  margin-left: 0;
  /* height: 191px; */
  font-size: 1.3em;
}

.wallpostuser {
  display: flex;
  width: 220px;
}

.wallPost {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.ppcomment {
  height: 35px;
}

.usercommenting {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.wallbody {
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
}

.fullbodywall {
  background-color: #f0f2f5;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
  justify-content: space-evenly;
}

.wallpic {
  background-size: cover;
  width: 88%;
  height: auto;
  cursor: pointer;
}

.wallpic2 {
  background-size: cover;
  width: 50%;
  height: auto;
}

.posttop {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.posttopuser {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
}

.ppwall {
  height: 100%;
  width: auto;
  padding-right: 10px;
}

.ppusercontainer {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.ppusercontainer2 {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  margin-bottom: 20px;
}

.ppusercontainercomment {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.posttopbtns {
  margin: 5px;
}

.wallForm3 {
  position: relative;
  left: 50px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  top: 20px;
  background-color: white;
  width: 650px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}

.posttopbtns i {
  margin: 5px;
  font-size: 1em;
}

.postdate {
  width: 100px;
}

.posttopuserinfo {
  display: flex;
  flex-direction: column;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: lightgrey;
  height: 1px;
  margin-bottom: 10px;
}

#comment {
  height: 35px;
  width: 445px;
  border-radius: 25px;
  font-size: 1em;
  padding-left: 20px;
  border: none;
  background-color: #f0f2f5;
}
#comment2 {
  height: 35px;
  width: 87%;
  border-radius: 25px;
  font-size: 1em;
  padding-left: 20px;
  border: none;
  background-color: #f0f2f5;
}

.commentForm {
  display: flex;
  margin-top: 20px;
}

.fa {
  cursor: pointer;
}

.commentsubbtn {
  margin-left: 5px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: none;
  cursor: pointer;
}

.posttitle {
  font-size: 16px;

  align-self: flex-start;
  margin-left: 20px;
  cursor: pointer;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 1em;
}

.postsubtitle {
  margin-top: 4px;
  margin-bottom: 18px;
  align-self: flex-start;
  margin-left: 20px;
  font-size: 1em;
}

.completecomment.commentpart {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 500px;
}

.commentpart {
  margin: 10px;
  margin-top: 20px;
  display: flex;
  width: 92%;
}

.commentinside {
  padding: 13px;
  display: flex;
  justify-content: space-between;
  background-color: #f0f2f5;
  border-radius: 15px;
  width: 100%;
  margin: -8px;
}
.commentuserinfo {
  font-size: 0.8em;
}

.commenttext {
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 1em;
}

.wallForm {
  /* position: relative; */
  /* left: 50px; */
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: white;
  width: 575px;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}
.wallForm2 {
  position: relative;
  left: 50px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  top: 20px;
  background-color: white;
  width: 420px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px darkgrey;
}

.labelwall {
  display: inline-block;
  font-size: 15px;
  width: 80px;
  text-align: left;
}
.inputwall {
  margin: 5px;
  width: 350px;
  height: 30px;
  font-size: 15px;
  border: none;
  background-color: #f0f2f5;
  border-radius: 15px;
  height: 35px;
  padding: 10px;
}

.btnaddwall {
  padding: 10px;
  width: 280px;
  height: 50px;
  align-self: center;
  border: none;
  background-color: #4a7f37;
  border-radius: 8px;
  color: white;
  font-size: 1em;
}

.profiletoppost {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.posttitleprofile {
  width: 600px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.userpagetitle {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 40px;
}

.searchform {
  margin-left: 0px;
  margin-top: 5px;
  width: 300px;
  background-color: white;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 1px 1px 2px grey;
}

.searchformuserpage {
  margin-left: 50px;
  margin-top: 20px;
  width: 350px;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.usercontainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  width: 265px;
  align-items: center;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}

.usercontainerpage {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 20px;
  background-color: white;
  width: 500px;
  align-items: center;
}

.allusercontainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  width: 300px;
  align-items: center;
  padding: 15px;
  height: 550px;
  overflow-x: hidden;
  box-shadow: 1px 1px 2px grey;
}

.allusercontainer b {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 14px;
}

.noplants {
  margin-left: 50px;
  margin-top: 50px;
  font-size: 1.2em;
}

.allusercontainer::-webkit-scrollbar {
  width: 0.4em;
}

.allusercontainer::-webkit-scrollbar-track {
  box-shadow: inset 1px 1px 6px rgba(96, 219, 14, 0.3);
}

.allusercontainer::-webkit-scrollbar-thumb {
  background-color: #4a7f37;
  outline: 1px solid rgb(1, 29, 5);
}

.allusercontainerpage {
  display: flex;
  flex-direction: column;
  align-self: left;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  width: 600px;
  align-items: center;
  margin-left: 50px;
}

.hruser {
  width: 100%;

  margin: 10px;
}

.wallFullPage {
  display: flex;
  flex-direction: column;
}

.userswallpageee {
}

.fulluserpagebody {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  margin-top: 25px;
}
p {
  font-size: 0.8em;
}

.oneuserbody {
  position: relative;
  top: 20px;
}

.userplantsbody {
  display: flex;
  flex-direction: column;
}

.wallpagecss {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.userplantscontainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.usersplants {
  display: flex;
}

.wallbodyprofile {
  position: relative;
  top: 50px;
}

.fullbodyprofile {
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fileplant {
  font-size: 1em;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  border-radius: 15px;
  padding: 10px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 200;
  color: white;
  background-color: #4a7f37;
  display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #4a7f37;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

.usernameppfu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.wallpage {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: baseline;
}

.tempimgwall {
  width: 350px;
  height: auto;
  display: flex;
  margin-left: 60px;
  margin-top: 20px;
  border-radius: 20px;
}

.loadingwallgif {
  height: 35px;
}

.loadinggifwall {
  height: 100px;
  margin-left: 70px;
}

.myprofiletitle {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  width: 20%;
}

.fa-user-cog {
  font-size: 3em;
}

.oneposttitle {
  color: white;
  margin-bottom: 20px;
  position: fixed;
  left: 20px;
  top: 80px;
  font-weight: 300;
}

.onepostimage {
  height: 85%;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-self: flex-end;
}

.onepostfullscreen {
  position: fixed;
  padding-top: 68px;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(34, 34, 34, 0.9);
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.deletescreen {
  position: fixed;
  padding-top: 68px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(34, 34, 34, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.userlikes {
  position: fixed;
  padding-top: 68px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(34, 34, 34, 0.9);
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.yesnobtns {
  display: flex;
  justify-content: space-around;
  width: 250px;
}

.yesnobtns > button:first-child {
  height: 50px;
  width: 100px;
  background-color: darkgreen;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.yesnobtns > button:last-child {
  height: 50px;
  width: 100px;
  background-color: firebrick;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.yesnobtns > button:hover {
  box-shadow: 1px 1px 2px black;
}

.deletescreen h2 {
  color: white;
  font-size: 5vh;
  text-align: center;
  margin-bottom: 50px;
}

.onepostright {
  padding-top: 5px;
  padding-left: 15px;
  display: flex;
  width: 30%;
  height: 95%;
  background-color: white;
}

.closefull {
  font-size: 1.5em;
  font-weight: 800;
  position: fixed;
  height: 50px;
  width: 50px;
  top: 10px;
  text-align: center;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closefull:hover {
  background-color: rgba(95, 94, 94, 0.9);
  border-radius: 100%;
}

.oneposttitleimage {
  height: 95%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.oneposttitleimage {
  background-color: rgba(34, 34, 34, 0.7);
  height: 95%;
}
.commentpart2 {
  margin: 10px;
  margin-top: 20px;
  display: flex;
  width: 100%;
}
/* 
.commentpartcontainer {
  margin-top: -24px;
  margin-bottom: -10px;
} */

@media screen and (max-width: 540px) {
  /* For mobile phones: */

  .signin_left_subtitle {
    text-align: center;
    color: white;
    font-size: 1em;
    font-family: Roboto;
    font-weight: 200;
    text-shadow: 16px 16px 32px black;

    margin-bottom: 25px;
  }

  .signin_left_title {
    font-size: 6em;
  }

  .homeinfos {
    display: none;
  }

  .wallPost {
    display: flex;
    flex-direction: column;
    /* align-self: center; */
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .signin_right {
    padding: 15px;
    border-radius: 15px;
    background-color: rgba(230, 230, 230, 0.8);
    height: 288px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96%;
    font-size: 14px;
  }

  .wallbody {
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .signin {
    background-image: url(https://cdn.apartmenttherapy.info/image/upload/v1586153210/at/news-culture/2020-04/200402-bloomscape-zoom-2.png);
    display: flex;
    background-size: cover;
    /* -webkit-mask-size: cover; */
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    align-content: center;
  }

  .signin_left {
    display: flex;
    background: transparent;
    flex-direction: column;
    height: 200px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    margin-bottom: 15px;
    color: white;
    margin-top: 20px;
  }

  form {
    width: 100%;
  }

  .walltitle {
    margin-top: -20px;
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 300;
  }

  .commentform {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .usercontainerwidth {
    width: 95%;
  }

  .wallForm2 {
    position: absolute;
    left: 0px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    top: 60px;
    background-color: white;
    width: 100%;
    font-size: 14px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .fulluserpagebody {
    position: relative;
    top: 90px;
    display: none;
  }

  .allusercontainerpage {
    display: flex;
    flex-direction: column;
    align-self: left;
    background-color: white;
    border-radius: 15px;
    width: 100%;
    align-items: flex-start;
    margin-left: 0px;
  }

  .searchformuserpage {
    margin-left: 0px;
    margin-top: 15px;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 15px;
  }

  .usercontainerpage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    background-color: white;
    width: 100%;
    align-items: center;
  }
  .flexuserpage {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }

  .flexuserpageprofile {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }
  .wallpagecss {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .userpagebodyuserpage {
    width: 100%;
  }
  .searchformuserpage {
    margin-left: 0px;
    margin-top: 15px;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 15px;
  }

  .wallfuldiv {
    margin-top: 25px;
  }
  .wallForm {
    position: relative;
    left: 0px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: white;
    padding: 15px 21px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px darkgrey;
    width: 100%;
    font-size: 14px;
  }

  .btn-submit-plant {
    margin-top: 20px;
    margin-left: 0px;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #4a7f37;
    box-shadow: 2px 2px 4px darkgrey;
    color: white;
    font-size: 1em;
    border: none;
    cursor: pointer;
  }

  .inputwall {
    margin: 5px;
    width: 100%;
    height: 30px;
    font-size: 1em;
    border: none;
    background-color: #f0f2f5;
    border-radius: 15px;
    height: 35px;
    padding: 10px;
  }

  .ppusercontainercomment {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
  }

  .postsubtitle {
    margin-top: 0px;
    margin-bottom: 18px;
    align-self: flex-start;
    margin-left: 15px;
    font-size: 1em;
  }

  .posttitle {
    font-size: 1em;
    margin-top: 0px;
    margin-bottom: 5px;
    align-self: flex-start;
    margin-left: 15px;
  }

  .wallpostcontainer {
    padding-top: 0px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-left: 0px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 100%;
    position: relative;
    left: 0px;
    background-color: white;
    align-self: center;
    border-radius: 8px;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .commentForm {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
  }

  #comment {
    /* margin-top: 0px; */
    height: 35px;
    width: 70vw;
    border-radius: 25px;
    font-size: 1em;
    padding-left: 20px;
    border: none;
    background-color: #f0f2f5;
  }
  .ppusercontainer {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
  }
  .commentpartcontainer {
    margin-left: 10px;
    margin-right: 0px;
    width: 94%;
    margin-top: -12px;
  }

  b.usernames {
    font-weight: 400;
  }

  .commenttext {
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 14px;
  }

  .commentinside {
    padding: 13px;
    display: flex;
    justify-content: space-between;
    background-color: #f0f2f5;
    border-radius: 15px;
    width: 100%;
    margin: 0px;
  }

  .usercommenting {
    display: flex;
    flex-direction: row;
    margin: 0px;
  }
  .commentpart {
    margin: 0px;
    margin-top: 20px;
    display: flex;
    width: 96%;
  }

  .tempimgwall {
    width: 190px;
    height: auto;
    display: flex;
    margin-left: 0;
    margin-top: 20px;
  }

  .loadingwallgif {
    height: 25px;
  }

  .loadinggifwall {
    height: 60px;
    width: 60px;
    align-self: center;
    margin-left: 0px;
    margin-top: 20px;
  }
  .logoApp {
    font-family: Just Another Hand;
    font-size: 2.6em;
    font-weight: 200;
    display: none;
  }

  .NavMain {
    justify-content: space-around;
    position: -webkit-sticky;
    position: sticky;
    /* padding-top: 32px; */
    top: 0;
    background-color: #4a7f37;
    color: white;
    display: flex;
    align-items: center;
    /* padding-top: 3px; */
    height: 30px;
    width: 100%;
    font-size: 13px;
    z-index: 2;
  }

  .commentsubbtn {
    margin-left: 5px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border: none;
    cursor: pointer;
  }

  .PlantForm {
    margin-left: 0px;
    margin-bottom: 5px;
    margin-top: 20px;
    background-color: white;
    width: 350px;
    padding: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .userpagetitle {
    font-size: 28px;
  }

  .myplantspage {
    background-color: #f0f2f5;
    width: 99%;
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .myplantstitle {
    margin-top: 5px;
  }

  .addplantbtn {
    margin-top: 20px;
    margin-left: 0px;
    margin-bottom: 5px;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    background-color: #4a7f37;
    color: white;
    font-size: 14px;
    border: none;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .PlantFormContainer {
    display: flex;
    width: 350px;
    flex-direction: column;
  }

  .inputplant {
    margin: 5px;
    width: 100%;
    height: 30px;
    font-size: 0.9em;
    border: none;
    background-color: #f0f2f5;
    border-radius: 15px;
    padding: 15px;
  }

  .selectplant {
    width: 100%;
    margin: 5px;
    height: 30px;
    font-size: 0.9em;
    border: none;
    border-radius: 15px;
    padding: 5px;
    background-color: #f0f2f5;
  }

  .editplantcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .plantcards {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: center;
    margin-left: 0px;
  }

  .givemewater {
    font-size: 1em;
    margin-top: 20px;
    border-radius: 6px;
    width: 120px;
    height: 33px;
    align-self: center;
    background-color: #2da5dd;
    box-shadow: 2px 2px 4px darkgrey;
    border: none;
    cursor: pointer;
    font-weight: 600;
  }

  .plantcardimage {
    width: 100%;
    height: 150px;
    margin: 15px;
    align-items: center;
    align-self: center;
  }

  .plantcardname {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 800;
    margin-left: 8px;
  }

  .plantcard {
    display: flex;
    flex-direction: column;
    height: 365px;
    width: 215px;
    margin: 8px;
    font-size: 14px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 2px 2px 4px darkgrey;
    text-align: left;
  }

  .formEditplant {
    position: relative;
    top: 20px;
    background-color: white;
    padding: 15px;
    width: 330px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px lightgrey;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .formplantimg {
    width: 181px;
    height: auto;
    display: flex;
    margin-top: 12px;
    margin-left: 0px;
  }
  .PlantForm {
    margin-left: 0px;
    margin-bottom: 5px;
    margin-top: 20px;
    background-color: white;
    width: 350px;
    padding: 30px;
    font-size: 14px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .formeditplantimg {
    width: 310px;
    height: auto;
    display: flex;
    margin-left: 0;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
    margin-bottom: 25px;
  }

  .userplantspartright {
    position: relative;
    top: -50px;
  }

  .myprofiletitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    margin-bottom: 20px;
  }

  .fa-user-cog {
    font-size: 2em;
  }
  .fa-location-arrow {
    font-size: 0.8em;
  }
  .NavMain a {
    font-weight: 800;
    margin: 2px;
  }

  .NavMain .nav-list {
    margin-left: 0px;
    display: flex;
  }
}

@media screen and (max-width: 320px) {
  .NavMain {
    justify-content: space-around;
    position: -webkit-sticky;
    position: sticky;
    /* padding-top: 32px; */
    top: 0;
    background-color: #4a7f37;
    color: white;
    display: flex;
    align-items: center;
    /* padding-top: 3px; */
    height: 30px;
    width: 100%;
    font-size: 10.2px;
    z-index: 2;
  }

  .myprofiletitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 65%;
    margin-bottom: 20px;
  }
}

