@media screen and (max-width: 540px) {
  /* For mobile phones: */

  .signin_left_subtitle {
    text-align: center;
    color: white;
    font-size: 1em;
    font-family: Roboto;
    font-weight: 200;
    text-shadow: 16px 16px 32px black;

    margin-bottom: 25px;
  }

  .signin_left_title {
    font-size: 6em;
  }

  .homeinfos {
    display: none;
  }

  .wallPost {
    display: flex;
    flex-direction: column;
    /* align-self: center; */
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .signin_right {
    padding: 15px;
    border-radius: 15px;
    background-color: rgba(230, 230, 230, 0.8);
    height: 288px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96%;
    font-size: 14px;
  }

  .wallbody {
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .signin {
    background-image: url(https://cdn.apartmenttherapy.info/image/upload/v1586153210/at/news-culture/2020-04/200402-bloomscape-zoom-2.png);
    display: flex;
    background-size: cover;
    /* -webkit-mask-size: cover; */
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    align-content: center;
  }

  .signin_left {
    display: flex;
    background: transparent;
    flex-direction: column;
    height: 200px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    /* align-content: center; */
    margin-bottom: 15px;
    color: white;
    margin-top: 20px;
  }

  form {
    width: 100%;
  }

  .walltitle {
    margin-top: -20px;
    text-align: center;
    font-family: Roboto;
    font-size: 30px;
    font-weight: 300;
  }

  .commentform {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .usercontainerwidth {
    width: 95%;
  }

  .wallForm2 {
    position: absolute;
    left: 0px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    top: 60px;
    background-color: white;
    width: 100%;
    font-size: 14px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .fulluserpagebody {
    position: relative;
    top: 90px;
    display: none;
  }

  .allusercontainerpage {
    display: flex;
    flex-direction: column;
    align-self: left;
    background-color: white;
    border-radius: 15px;
    width: 100%;
    align-items: flex-start;
    margin-left: 0px;
  }

  .searchformuserpage {
    margin-left: 0px;
    margin-top: 15px;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 15px;
  }

  .usercontainerpage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    background-color: white;
    width: 100%;
    align-items: center;
  }
  .flexuserpage {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }

  .flexuserpageprofile {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }
  .wallpagecss {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .userpagebodyuserpage {
    width: 100%;
  }
  .searchformuserpage {
    margin-left: 0px;
    margin-top: 15px;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 15px;
  }

  .wallfuldiv {
    margin-top: 25px;
  }
  .wallForm {
    position: relative;
    left: 0px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: white;
    padding: 15px 21px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px darkgrey;
    width: 100%;
    font-size: 14px;
  }

  .btn-submit-plant {
    margin-top: 20px;
    margin-left: 0px;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #4a7f37;
    box-shadow: 2px 2px 4px darkgrey;
    color: white;
    font-size: 1em;
    border: none;
    cursor: pointer;
  }

  .inputwall {
    margin: 5px;
    width: 100%;
    height: 30px;
    font-size: 1em;
    border: none;
    background-color: #f0f2f5;
    border-radius: 15px;
    height: 35px;
    padding: 10px;
  }

  .ppusercontainercomment {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
  }

  .postsubtitle {
    margin-top: 0px;
    margin-bottom: 18px;
    align-self: flex-start;
    margin-left: 15px;
    font-size: 1em;
  }

  .posttitle {
    font-size: 1em;
    margin-top: 0px;
    margin-bottom: 5px;
    align-self: flex-start;
    margin-left: 15px;
  }

  .wallpostcontainer {
    padding-top: 0px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-left: 0px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 100%;
    position: relative;
    left: 0px;
    background-color: white;
    align-self: center;
    border-radius: 8px;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .commentForm {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
  }

  #comment {
    /* margin-top: 0px; */
    height: 35px;
    width: 70vw;
    border-radius: 25px;
    font-size: 1em;
    padding-left: 20px;
    border: none;
    background-color: #f0f2f5;
  }
  .ppusercontainer {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
  }
  .commentpartcontainer {
    margin-left: 10px;
    margin-right: 0px;
    width: 94%;
    margin-top: -12px;
  }

  b.usernames {
    font-weight: 400;
  }

  .commenttext {
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 14px;
  }

  .commentinside {
    padding: 13px;
    display: flex;
    justify-content: space-between;
    background-color: #f0f2f5;
    border-radius: 15px;
    width: 100%;
    margin: 0px;
  }

  .usercommenting {
    display: flex;
    flex-direction: row;
    margin: 0px;
  }
  .commentpart {
    margin: 0px;
    margin-top: 20px;
    display: flex;
    width: 96%;
  }

  .tempimgwall {
    width: 190px;
    height: auto;
    display: flex;
    margin-left: 0;
    margin-top: 20px;
  }

  .loadingwallgif {
    height: 25px;
  }

  .loadinggifwall {
    height: 60px;
    width: 60px;
    align-self: center;
    margin-left: 0px;
    margin-top: 20px;
  }
  .logoApp {
    font-family: Just Another Hand;
    font-size: 2.6em;
    font-weight: 200;
    display: none;
  }

  .NavMain {
    justify-content: space-around;
    position: sticky;
    /* padding-top: 32px; */
    top: 0;
    background-color: #4a7f37;
    color: white;
    display: flex;
    align-items: center;
    /* padding-top: 3px; */
    height: 30px;
    width: 100%;
    font-size: 13px;
    z-index: 2;
  }

  .commentsubbtn {
    margin-left: 5px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border: none;
    cursor: pointer;
  }

  .PlantForm {
    margin-left: 0px;
    margin-bottom: 5px;
    margin-top: 20px;
    background-color: white;
    width: 350px;
    padding: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .userpagetitle {
    font-size: 28px;
  }

  .myplantspage {
    background-color: #f0f2f5;
    width: 99%;
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .myplantstitle {
    margin-top: 5px;
  }

  .addplantbtn {
    margin-top: 20px;
    margin-left: 0px;
    margin-bottom: 5px;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    background-color: #4a7f37;
    color: white;
    font-size: 14px;
    border: none;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .PlantFormContainer {
    display: flex;
    width: 350px;
    flex-direction: column;
  }

  .inputplant {
    margin: 5px;
    width: 100%;
    height: 30px;
    font-size: 0.9em;
    border: none;
    background-color: #f0f2f5;
    border-radius: 15px;
    padding: 15px;
  }

  .selectplant {
    width: 100%;
    margin: 5px;
    height: 30px;
    font-size: 0.9em;
    border: none;
    border-radius: 15px;
    padding: 5px;
    background-color: #f0f2f5;
  }

  .editplantcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .plantcards {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: center;
    margin-left: 0px;
  }

  .givemewater {
    font-size: 1em;
    margin-top: 20px;
    border-radius: 6px;
    width: 120px;
    height: 33px;
    align-self: center;
    background-color: #2da5dd;
    box-shadow: 2px 2px 4px darkgrey;
    border: none;
    cursor: pointer;
    font-weight: 600;
  }

  .plantcardimage {
    width: 100%;
    height: 150px;
    margin: 15px;
    align-items: center;
    align-self: center;
  }

  .plantcardname {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 800;
    margin-left: 8px;
  }

  .plantcard {
    display: flex;
    flex-direction: column;
    height: 365px;
    width: 215px;
    margin: 8px;
    font-size: 14px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 2px 2px 4px darkgrey;
    text-align: left;
  }

  .formEditplant {
    position: relative;
    top: 20px;
    background-color: white;
    padding: 15px;
    width: 330px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px lightgrey;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .formplantimg {
    width: 181px;
    height: auto;
    display: flex;
    margin-top: 12px;
    margin-left: 0px;
  }
  .PlantForm {
    margin-left: 0px;
    margin-bottom: 5px;
    margin-top: 20px;
    background-color: white;
    width: 350px;
    padding: 30px;
    font-size: 14px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px darkgrey;
  }

  .formeditplantimg {
    width: 310px;
    height: auto;
    display: flex;
    margin-left: 0;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
    margin-bottom: 25px;
  }

  .userplantspartright {
    position: relative;
    top: -50px;
  }

  .myprofiletitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    margin-bottom: 20px;
  }

  .fa-user-cog {
    font-size: 2em;
  }
  .fa-location-arrow {
    font-size: 0.8em;
  }
  .NavMain a {
    font-weight: 800;
    margin: 2px;
  }

  .NavMain .nav-list {
    margin-left: 0px;
    display: flex;
  }
}

@media screen and (max-width: 320px) {
  .NavMain {
    justify-content: space-around;
    position: sticky;
    /* padding-top: 32px; */
    top: 0;
    background-color: #4a7f37;
    color: white;
    display: flex;
    align-items: center;
    /* padding-top: 3px; */
    height: 30px;
    width: 100%;
    font-size: 10.2px;
    z-index: 2;
  }

  .myprofiletitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 65%;
    margin-bottom: 20px;
  }
}
