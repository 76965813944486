.UploadWidget {
  border: 1px solid #5663ff;
  color: #5663ff;
  border-radius: 4px;
  display: inline-block;
  padding: 10px 22px;
  cursor: pointer;
  margin-bottom: 20px;
}

.UploadWidget.input[type="file"] {
  display: none !important;
}
