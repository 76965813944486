@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");

.NavMain {
  position: sticky;
  top: 0;
  background-color: #4a7f37;
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  height: 55px;
  width: 100%;
  z-index: 2;
}

.NavMain .nav-list {
  margin-left: auto;
  display: flex;
}

.NavMain .nav-list li:not(:last-child) {
  margin-right: 20px;
}

.logoApp {
  font-family: Just Another Hand;
  font-size: 2.6em;
  font-weight: 200;
}

.NavMain a {
  font-weight: 500;
  margin: 5px;
}
