@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap");

.signin {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.signinform {
  display: flex;
  flex-direction: column;
}

.signin_left {
  display: flex;
  flex-direction: column;
  background-image: url("https://cdn.apartmenttherapy.info/image/upload/v1586153210/at/news-culture/2020-04/200402-bloomscape-zoom-2.png");
  height: 100vh;
  width: 70%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  color: white;
}

.signin_left_title {
  text-align: center;
  color: white;
  font-size: 11em;
  margin-bottom: 0;
  font-family: Just Another Hand;
  font-weight: 300;
  text-shadow: 8px 8px 16px black;
}

.signin_left_subtitle {
  text-align: center;
  color: white;
  font-size: 3em;
  font-family: Roboto;
  font-weight: 200;
  text-shadow: 16px 16px 32px black;
  margin-top: 20px;
  padding-top: 20px;
  margin-bottom: 50px;
}

.signin_right {
  padding: 15px;
  width: 25%;
  border-radius: 15px;
  background-color: white;
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
}

.signin_right_text {
  margin-top: 20px;
  color: black;
  font-size: 1.2em;
}

.signinform_input {
  font-size: 1.5em;
  opacity: 0.95;
  border: none;
  margin: 10px;
  border-bottom: 2px black solid;
  background: transparent;
  font-weight: 100;
  color: black;
}

.signinform_button {
  margin-top: 20px;
  width: 120px;
  height: 35px;
  font-size: 1em;
  background-color: #4a7f37;
  color: white;
  border-radius: 12px;
  border: none;
  box-shadow: 1px 1px 2px grey;
  align-self: center;
  cursor: pointer;
  padding: 25px;
  justify-self: center;
  justify-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signincontainer {
  cursor: default;
}

.here:hover {
  color: darkgreen;
  font-weight: 800;
}

.signinform_button:hover {
  background-color: darkgreen;
  box-shadow: 2px 2px 4px grey;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

.signinform_label {
  color: black;
  font-size: 1.2em;
  margin-left: 10px;
}

/* background-image: url("https://cdn.apartmenttherapy.info/image/upload/v1586153210/at/news-culture/2020-04/200402-bloomscape-zoom-2.png"); */

.containertext {
  font-size: 1.5em;
  font-weight: 100;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.homecontainer h3 {
  font-size: 2.2em;
  text-align: center;
  line-height: 1em;
}

.homeinfos {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
}

.homecontainer {
  width: 220px;
}
